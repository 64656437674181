@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
:root {
  --clr-primary: #2e9cca;
  --clr-primary-dark: #29648a;
  /* --clr-secondary: #464866; */
  --clr-secondary-dark: #25274d;
  --clr-secondary-light: #aaabb8;
  --clr-white: #fff;
  --ff-primary: "Lato", sans-serif;
  --transition: all 1s;
  --spacing: 0.25rem;
  --radius: 0.5rem;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* font-family: Helvetica, Arial, sans-serif; */
  /* font-family: Open Sans; */
  touch-action: manipulation; /* to avoid double-tapping */
}

html {
  /* font-family: sans-serif; */
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c6a74;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
form textarea,
text {
  resize: none;
}
form.label {
  color: white;
}
label {
  /* font-size:18px; */
  vertical-align: top;
}
.container-question-options label {
  display: inline-block;
}
input[type="radio"] {
  vertical-align: middle;
}
.div-screenshot-image {
  height: 100px;
  width: 150px;
}
.student-photo {
  width: 30%;
}
.reference-photo {
  width: 85%;
}
.img-Question {
  width: 90%;
  margin-bottom: 5px;
}
.myborder {
  border: 3px solid black;
  /* border: none; */
}
.presentation-image {
  border: 3px solid black;
  padding: 20px;
  background-color: black;
}

/* 2023 -- below one is very important */
.div-center {
  display: inline-block;
  text-align: justify;
}
.payment-info {
  white-space: pre-line;
  line-height: 125%;
  font-size: 1.2rem;
  text-align: justify;
  padding: 5px;
  border: 2px solid cyan;
}
.txt-abcd {
  white-space: pre-line;
  line-height: 125%;
  font-size: 1.2rem;
  text-align: justify;
}
/*~~~~~~~~~~~~~~~~ All Containers ~~~~~~~~~~~~~~~~*/
.container-navbar {
  padding: 3px;
  margin-bottom: 10px;
  width: 90%;
}
.container-navitems {
  margin-left: auto;
}
.container-jumbotron {
  position: relative;
}
.div-student-image {
  /* height: 66px; */
  /* width: 100px; */
  height: 100px;
  width: 150px;
}
.text-block1 {
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
.text-block2 {
  position: absolute;
  left: 0;
  bottom: 0%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
.container-about-us,
.container-contact-us {
  width: 80%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
.container-popular-course {
  font-size: 1.1rem;
  padding-top: 5px;
  padding-bottom: 5px;
}
.container-section-menu {
  padding: 10px;
  color: white;
  border-radius: 5px;
}
.border-below
{
  border-bottom: 1px solid #4968ad;
}
.container-subjects {
  padding: 5px;
  margin-bottom: 5px;
}
.container-subject-list {
  padding: 0px;
}
.container-progam-details {
  width: 80%;
}
.container-actual-content {
  margin-left: 10px;
  margin-right: 10px;
  /* margin-top: 20px; */
  width: 90%;
  margin-bottom: 100px;
}

.container-student-login-form {
  margin-top: 10px;
}
.container-video {
  width: 100%;
}
.container-explore {
  padding: 5px;
}
.container-explore-program {
  border: 4px solid rgb(40, 22, 111);
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 20px;
}
.container-image-slider {
  margin-bottom: 10px;
}
.container-success-stories {
  border: 2px solid rgb(40, 22, 111);
  width: 80%;
}
.container-proven-formula {
  width: 80%;
  margin-bottom: 15px;
}
.container-chapters {
  border: 2px solid rgb(40, 22, 111);
  border-radius: 10px;
  margin-bottom: 10px;
}
.container-contact-info {
  width: 80%;
  margin-bottom: 15px;
  padding: 5px;
  border-left: 2px solid rgb(40, 22, 111);
  border-right: 2px solid rgb(40, 22, 111);
  border-bottom: 2px solid rgb(40, 22, 111);
}
.container-course-details {
  /* cursor: pointer; */
}
.container-yesno-buttons {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.div-contact-info {
  width: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.div-code-image-video {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(180, 224, 243);
}
.div-nav-menus {
  margin-bottom: 10px;
}
.div-list {
  /* width: 70%;
  margin-left: auto;
  margin-right: auto; */
  text-align: left;
}
.div-learning {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.div-course-fees {
  font-size: 1.3rem;
  /* color: red; */
}
.div-about-us {
  margin-top: 15px;
  font-size: 1.2rem;
}
.div-contact-us {
  margin-top: 20px;
  font-size: 1.5rem;
  text-align: center;
}
.div-google-reviews {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.1rem;
}
.div-rar {
  margin-bottom: 10px;
}
/* iframe {
  height: 1000px;
} */
iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.container-profile {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}
.container-referral-details {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
.container-profile-personal,
.container-profile-admission,
.container-profile-offers,
.container-profile-referrals {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
}
.container-profile-personal {
  margin-bottom: 10px;
}
.container-reference-page,
.container-certificate-page {
  margin-top: 10px;
}
.container-question-options {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.div-few-words {
  line-height: 120%;
  margin-bottom: 4px;
}
.div-program-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.div-info {
  margin-bottom: 10px;
}
.div-testStatus {
  font-style: italic;
  font-size: 0.9rem;
  color: red;
}
.div-question {
  width: 100%;
  padding-left: 5px;
  margin-bottom: 10px;
}
.div-option {
  text-align: justify;
  margin-top: 2px;
  margin-bottom: 2px;
}
p-explanation {
  padding-left: 10px;
}
/*--------------Customized color light ----------------*/
.btn-lightcolor {
  padding: 5px;
  border: none;
  background-color: rgb(180, 224, 243);
  color: rgb(40, 22, 111);
}
.text-lightcolor {
  color: rgb(180, 224, 243);
}
.bg-lightcolor {
  background-color: rgb(180, 224, 243);
}
.bg-secondary {
  background-color: #f2f1f8;
}
/*--------------Customized color primary ----------------*/
.btn-primarycolor {
  padding: 5px;
  border: none;
  background-color: rgb(40, 22, 111);
  color: rgb(0, 147, 221);
}
.text-primarycolor {
  color: rgb(40, 22, 111);
}
.bg-primarycolor {
  background-color: rgb(40, 22, 111);
}
/*--------------Customized color dark ----------------*/
.btn-darkcolor {
  padding: 5px;
  border: none;
  /* background-color: rgb(0, 147, 221); */
  background-color: rgb(40, 22, 111);
  color: white;
}
.text-darkcolor {
  /* color: rgb(0, 147, 221); */
  color: rgb(40, 22, 111);
}
.bg-darkcolor {
  background-color: rgb(40, 22, 111);
}
/*--------------Customized color white ----------------*/
.text-whitecolor {
  color: white;
}
.bg-whitecolor {
  background-color: white;
}
.text-white {
  color: #fff !important;
}
bg-transparent {
  background-color: transparent;
}
/*--------------Customized color black ----------------*/
.text-blackcolor {
  color: black;
}
.bg-blackcolor {
  background-color: black;
}
/* ----------Images---------------------- */
.container-image-advt-course {
  /* height:400px; */
  overflow: auto;
}
.image-advt-course {
  /* height:200px; */
  /* overflow: auto; */
  height: 100%;
}
.homepage-small-logo {
  height: 80px;
}

.masterlist-image {
  width: 60%;
}
.show-image {
  align-self: flex-end;
}
/* ----------------Buttons---------------- */
/* Its width is made 100% for small devices (AT THE END OF THIS FILE) */
button {
  border: none;
}
button:focus {
  box-shadow: none !important;
}
.button-subject {
  margin: 1px;
  text-align: left;
}
.button-subject:hover {
  background-color: rgb(180, 224, 243);
  color: black;
}
.button-chapter {
  margin: 2px;
  text-align: left;
  width: 100%;
  padding: 10px;
}
.button-chapter:hover {
  /* background-color: rgb(180, 224, 243); */
  color: rgb(180, 224, 243);
  /* color: black; */
}
.button-menu {
  margin: 2px;
  border-radius: 5px;
}
.div-actual-content {
  padding: 4px;
  text-align: left;
  font-size: 0.9rem;
  background-color: rgb(180, 224, 243);
  width: 100%;
  border: none;
  cursor: hand;
}
.button-section-menu {
  padding: 1px;
  color: white;
  border: none;
}
.button-explore {
  padding: 8px;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 5px;
  border: none;
}
.button-course-content {
  padding: 10px 20px 10px;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
}

/* ----------------Text---------------- */
.bigger-text {
  font-size: 1.1em;
  color: black;
}

.text-bigger {
  font-size: 1.1em;
  color: black;
}
.text-smaller {
  font-size: 0.9em;
  color: black;
}
.text-thick {
  font-weight: bold;
}
.text-striked {
  text-decoration: line-through;
}
.error-text {
  color: red;
  /* font-size: 0.9rem; */
}
.text-error {
  color: red;
  /* font-size: 0.9rem; */
}
.text-loading {
  color: blue;
  font-size: 0.9em;
  font-style: italic;
  text-align: center;
}

.text-message {
  color: red;
  /* font-size: 0.9rem; */
}
.div-highlight {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  font-size: 1rem;
  background-color: red;
}
.text-highlight {
  text-align: center;
}

.duration-text {
  color: white;
  background-color: #974578;
}
.text-duration {
  color: white;
  background-color: #974578;
}
.thick-red-text {
  color: red;
  font-weight: bold;
}
.text-dropdown-button {
  font-size: 40px;
  color: red;
}
.text-instructions {
  margin-left: 30px;
  text-align: left;
  font-size: 00.9rem;
  margin-bottom: 5px;
}
.div-subject-description {
  margin-bottom: 2px;
}
.div-highlights {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;
  font-weight: bold;
}
.flex-grow {
  flex: 1 1;
}
.table {
  font-size: 0.9rem;
}

.homepage-button {
  margin-bottom: 20px;
}
.bullet-subject {
  font-size: 0.7rem;
}
.program-description {
  margin: 10px;
  padding: 5px 10px;
}
.wrapper-btn-play {
  width: 30%;
}
.select-element {
  margin-bottom: 15px;
}
.dropdown-menu {
  /* border: 14px dotted black; */
}
.dropdown-menu-noborder {
  /* border: 2px dotted  black ; */
  border: none;
  padding: 0px;
}
.active {
  background-color: green;
}
.program-code {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
}
.search-icon {
  padding-right: -10px;
}
.google-image {
  height: 20px;
}
.search-by-category a:hover {
  text-decoration: none;
}
.search-by-brand a {
  text-decoration: none;
}
.search-by-brand a:hover {
  text-decoration: none;
}
/* -----Social Follow--------- */
.social-container {
  background: #eee;
  padding: 25px 50px;
}
a.social {
  margin: 0 1rem;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  display: inline-block;
}
a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}

/*-----------------autocomplete----------------*/
/*------ downloaded here :https://blog.bitsrc.io/building-a-react-autocomplete-component-from-scratch-b78105324f4c */
.search-box {
  border: 1px solid black;
  border-radius: 2px;
  /* font-size: 2rem; */
  width: 100%;
  padding: 6px;
  transition: width 0.3s;
}
.search-box:focus {
  width: 100%;
  outline: none;
  border: 1px solid #08a1b6;
}
.search-btn {
  height: 100%;
  /* width: 4em; */
  margin-top: -2em;
  position: absolute;
  top: 50%;
  right: 0.5rem;

  opacity: 0.2;
  background-color: transparent;
  border: 0;
  background-repeat: no-repeat;
  background-size: 100%;
}

.search-btn:hover {
  outline: none;
  opacity: 0.4;
  cursor: pointer;
}
.search-btn:focus {
  outline: none;
  opacity: 0.6;
}
.no-options {
  color: white;
}
.form-heading {
  text-align: center;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-top: 10px;
}
.dropdown-menu {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.div-search,
.div-drop-down-category,
.div-drop-down-brand {
  margin-top: 3px;
}
.nav-bar img {
  height: 80%;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.active {
  background-color: red;
}
.container-login-form {
  margin-top: 150px;
}

.heading-saved-products {
  margin-top: 50px;
}
.text-header-added-product {
  text-align: center;
  font-weight: bolder;
}

.thick-text {
  font-weight: bold;
}

.smaller-text {
  font-size: 0.9rem;
}
.small-text {
  font-size: 0.75rem;
}

.my-btn {
  padding: 10px;
  border: none;
}

/*--------------Customized color cyan ----------------*/
.btn-mycyan {
  padding: 2px;
  border: none;
  background-color: rgb(0, 147, 221);
  color: white;
}
.bg-mycyan {
  background-color: rgb(117, 197, 240);
}
.text-mycyan {
  color: rgb(0, 147, 221);
}
.border-mycyan {
  border-bottom: 1px solid rgb(0, 147, 221);
}
.border-all-mycyan {
  border: 1px solid rgb(0, 147, 221);
}
/*--------------Customized color green ----------------*/
.btn-mygreen {
  padding: 10px;
  border: none;
  background-color: rgb(0, 146, 63);
  color: white;
}
.bg-mygreen {
  background-color: rgb(130, 200, 130);
}
.text-mygreen {
  color: rgb(0, 146, 63);
}
.border-mygreen {
  border-bottom: 1px solid rgb(0, 146, 63);
}
.border-all-mygreen {
  border: 1px solid rgb(0, 146, 63);
}
/* ---------------------- */
.btn-greycolor {
  padding: 5px;
  border: none;
  background-color: gray;
  color: white;
}
.btn-greencolor {
  padding: 5px;
  border: none;
  background-color: green;
  color: white;
}
.btn-redcolor {
  padding: 5px;
  border: none;
  background-color: red;
  color: white;
}
.text-greycolor {
  /* background-color: gray; */
  color: grey;
}
pre {
  /* max-width: 100%; */
  overflow: auto;
  color: black;
  /* font-family: Helvetica, Arial, sans-serif; */
}
.about-us-text {
  white-space: pre-line;
  margin-bottom: 100px;
}
.star {
  /* background-color: yellow; */
}

.btn-whatsApp {
  background-color: lightgreen;
  padding: 2px;
}
.admin-icons {
  color: grey;
  padding: 4px;
  margin: 0x 10px;
  border: none;
  display: inline-block;
}
.span-preview {
  /* padding-left: 2px;
  padding-right: 2px; */
}
Link {
  color: white;
  /* text-decoration: none; */
}
link:hover {
  text-decoration: none;
}
.container-list-table {
  margin-top: 150px;
}
.container-content-page {
  margin-top: 150px;
}
.star-radio-buttons {
  display: none;
}
.star-radio-buttons {
  cursor: pointer;
  transition: color 200ms;
}
/* smaller screens (upto 576px)*/
@media screen and (max-width: 768px) {
  html {
    /* font-size: 0.7rem; */
  }
  .logo-mobile {
    display: block;
  }
  .logo-desktop {
    display: none;
  }
  .container-image-advt-course {
    height: 400px;
    overflow: auto;
  }

  .form-student-login {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .div-logo {
    order: 1;
  }
  .div-promo2 {
    order: 2;
  }
  .div-toggler-button {
    order: 4;
  }
  .div-shopping-cart {
    order: 3;
  }
  .normal-text {
    display: none;
  }
  .short-text {
    display: inline-block;
  }
  .button-subject {
    width: 100%;
  }
  .VideoContainer {
    width: 50%;
  }
  .customer-form-shop-name {
    display: none;
  }
  .container-add-form {
    margin-top: 10px;
  }
}
/* Larger screens (more than 768.1px)*/
@media screen and (min-width: 768.1px) {
  html {
    /* font-size: 16px; */
  }
  .logo-mobile {
    display: none;
  }
  .logo-desktop {
    display: block;
  }
  iframe {
    width: 50%;
    aspect-ratio: 16 / 9;
  }
  .img-Question {
    width: 60%;
  }
  .container-about-us,
  .container-contact-us {
    width: 60%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }
  .container-profile {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
  }
  .container-referral-details {
    width: 60%;
  }
  .container-proven-formula {
    width: 40%;
  }
  .div-logo {
    order: 1;
  }
  .div-promo1 {
    order: 2;
  }
  .button-menu {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .div-toggler-button {
    order: 3;
  }
  .div-shopping-cart {
    order: 4;
  }
  .normal-text {
    display: inline-block;
  }
  .short-text {
    display: none;
  }
  .div-shopping-cart .btn {
    font-size: 1.5rem;
  }
  .card-special-offer {
    border-bottom: 2px solid red;
  }
  .div-shopping-cart .cart-count,
  .cart-amount {
    font-size: 1.5rem;
  }
  .customer-form-contact-details {
    position: fixed;
    bottom: 0;
  }
  .form-customer-entry {
    padding-left: 150px;
    padding-right: 150px;
  }
  .form-login {
    padding-left: 150px;
    padding-right: 150px;
  }
  .form-student-login {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .container-add-form {
    margin-top: 60px;
  }

  .nav-bar-logo-row {
    padding-left: 100px;
    padding-right: 100px;
  }

  .container-a-products {
    margin-top: 150px;
  }
  .container-a-quick-products {
    margin-top: 110px;
  }
}
.advt-page-highlight {
  background-color: #25274d;
  color: white;
  border-radius: 15px;
  padding: 10px;
  /* width: 90%; */
  text-align: center;
  margin: 10px auto;
}
.bulleted-data {
  /* width: 90%; */
  /* border: 2px solid black; */
}
/* Here it starts */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  /* color: #44425a; */
}

h1,
.h1 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

h2,
.h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3,
.h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4,
.h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

@media (max-width: 575.98px) {
  .carousel-caption h5 {
    font-size: 16px;
  }
  .carousel-caption h1 {
    font-size: 30px;
    font-weight: 700 !important;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  /* color: #44425a; */
}

h1,
.h1 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

h2,
.h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3,
.h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4,
.h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

body
{
    
    /* display:flex; */
    /* height: 100vh; */
    /* border: 2px solid black; */
    /* justify-content: center; */
    /* align-items: center; */
}
.container-login
{
    display:flex;
    /* max-width:80%;  */
    /* margin: 250px auto; */
    padding: 40px;
    border: 2px solid black;
    flex-direction: column;
}
.login-buttons
{
    margin: 20px;
}
body {
   background-color: white;
  }

